<script lang="ts">
    import toast from 'svelte-french-toast';
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import axios from 'axios';

    export let authUser;
    export let staticReferralMarketUsers;

    let showPopup = false;
    let selectedListing = null;

    function openPurchasePopup(offerId, userId, price) {
        selectedListing = { id: offerId, user_id: userId, price };
        showPopup = true;
    }

    function closePopup() {
        showPopup = false;
        selectedListing = null;
    }

    function purchaseUser() {
        if (!selectedListing) return;

        const { id: offerId, user_id: userId, price } = selectedListing;

        const placeBidButton = document.querySelector(`#button_${offerId}`);
        const buttonText = placeBidButton.querySelector('.buttonText');

        let values = {
            user_id: userId,
            offer_id: offerId,
        };

        axios
            .post('/purchase-referral-market-user', values)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Congrats! User has been purchased');
                    const divToRemove = document.getElementById(`wrapped-static-div-${offerId}`);
                    divToRemove.innerHTML = '';
                } else {
                    const errorMessage = response.data.message || 'An error occurred.';
                    toast.error(errorMessage);
                }
                closePopup();
            })
            .catch((error) => {
                console.error('Error:', error);
                closePopup();
            });
    }


</script>

<svelte:component this="{MarketplaceNav}" />

<div class="min-h-screen p-4">
    {#if staticReferralMarketUsers.length > 0}
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {#each staticReferralMarketUsers as offer}
                {#if offer.user.id != authUser}
                    <div id="{'wrapped-static-div-' + offer.id}" class="bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105">
                        <!-- Image -->
                        <a href="{offer.user.username}" class="block">
                            <img
                                src="{offer.user.avatar}"
                                alt="User Avatar"
                                class="w-full h-32 object-cover"
                            />
                        </a>
                        <!-- Content -->
                        <div class="p-3">
                            <a href="{window.location.host + '/' + offer.user.username}">
                                <h3 class="text-lg font-semibold text-gray-900 truncate">{offer.user.name}</h3>
                            </a>
                            <p class="text-sm text-gray-600">Price: <span class="font-bold">{offer.listing.price}</span></p>
                            <p class="text-sm text-gray-600">Followers: {offer.user.follower_amount}</p>
                            <div class="mt-3 flex justify-between items-center">
                                <a
                                    href="{'/' + offer.user.username}"
                                    class="text-xs text-indigo-500 hover:text-indigo-700 font-medium"
                                >
                                    View Profile
                                </a>
                                <button
                                    on:click|preventDefault="{() => openPurchasePopup(offer.id, offer.user_id, offer.listing.price)}"
                                    id="{'button_' + offer.id}"
                                    class="flex items-center justify-center bg-indigo-500 text-white text-xs font-semibold px-3 py-1 rounded hover:bg-indigo-600 transition duration-150"
                                >

                                    <span class="buttonText">Buy Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>
    {:else}
        <div class="ml-4 flex flex-col gap-y-2">
            <span class="text-lg font-semibold">Currently there are no profiles listed for sale.</span>
            <span>Come back later!</span>
        </div>
    {/if}
</div>

<!-- Tailwind CSS Popup -->
{#if showPopup && selectedListing}
    <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg shadow-xl max-w-sm w-full p-6">
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-bold text-gray-800">Confirm Purchase</h2>
                <button on:click="{closePopup}" class="text-gray-500 hover:text-gray-700">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
            <p class="text-gray-600 mb-6">
                Are you sure you want to purchase this user listing for
                <span class="font-semibold text-indigo-600">{selectedListing.price}</span>?
            </p>
            <div class="flex justify-end space-x-3">
                <button
                    on:click="{closePopup}"
                    class="px-3 py-1.5 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-150"
                >
                    Cancel
                </button>
                <button
                    on:click="{purchaseUser}"
                    class="px-3 py-1.5 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition duration-150"
                >
                    Confirm
                </button>
            </div>
        </div>
    </div>
{/if}